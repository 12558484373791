import firebase from 'firebase/app';
import 'firebase/firestore';
import { logger } from '@nykaa/logger';

import { DocumentName, DefaultCollection } from './constants';


interface Config{
  [key: string]: string,
}

export const listenCloudStore = (config: Config, actionCB : (data:any) => Promise<void> ) => {
  try {
    if (firebase) {
      if (!firebase.apps.length) {
        firebase.initializeApp(config || {});
       }
         const dataBaseRef = firebase.firestore();
       if (dataBaseRef) {
         const fireStoreCollection = config?.collection || DefaultCollection;
         const docName = config?.doc || DocumentName;
         const docRef = dataBaseRef.collection(fireStoreCollection).doc(docName);
         docRef.onSnapshot((doc: any) => {
           const data = doc.data();
           actionCB && actionCB(data);
         });
       }
    }
  }
  catch (e) {
    logger.error(e, 'Error in firebase listener');
  }
};

