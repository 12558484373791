import { Dispatch } from 'redux';
import { Data } from './types';
import actionTypes from './actionTypes';


export const changeData = (data: Data) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.VP_DATA_CHANGED,
    payload: data,
  });
};

export const setError = (flag = true, message: string) => (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.VP_BLOCK_ERROR,
    payload: {
      message,
      flag,
    },
  });
};

