import React, { useEffect, useCallback } from 'react';

import { initializeVisitorPrioritization } from './helper';
import Error from './Error';


const VisitorPrioritization = ({ isBlocked, changeData, data, setError, vpEnabled, config }: any) => {
  const onChange = useCallback((response) => {
    changeData(response);
  }, [changeData]);

  useEffect(() => {
    if (vpEnabled ) {
      initializeVisitorPrioritization(config, onChange);
    }
  }, [onChange, vpEnabled, config]);

  const onClose = ( ) => {
    setError(false);
    if (window && window.history) {
      window.history.back();
    }
  };

  if (isBlocked) {
    const { error_img_url: imgUrl, attributedTitle } = data;
    return (<Error imageUrl={imgUrl} attributedTitle={attributedTitle} onClose={onClose} />);
  }

  return (
    <></>
  );
};

export default VisitorPrioritization;
