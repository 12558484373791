import React from 'react';
import styled from 'App/styled';

import Modal from 'shared/components/Modal';


const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  max-height: 375px;
  min-height: 300px;
`;

const Close = styled.button`
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1), 0 -1px 7px 0 rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 5px;
  background-color: ${props => props.theme.brandPrimary};
  ${({ theme }) => theme.typography.titleXSmall};
  height: 50px;
  margin-top: 15px;
  padding: 10px 20px;
  max-width: 200px;
  height: 50px;
  color: #fff;
  width: 100%;
`;
const Error = ({ attributedTitle, imageUrl, onClose }: any) => (
  <Modal onHide={ () => onClose()}>
    <Wrapper>
      <div>
        <Img src={imageUrl}/>
      </div>
      <div dangerouslySetInnerHTML={{ __html:attributedTitle }} />
      <Close onClick={() => onClose()}>
        ok
      </Close>
    </Wrapper>
  </Modal>
  );

export default Error;
