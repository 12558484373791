import { connect } from 'react-redux';
import { State } from 'store/types';
import { changeData, setError } from 'store/visitorPrioritization/action';

import View from './View';


const mapStateToProps = (state: State) => {
  const { VP, remoteConfig } = state;
  return {
    isBlocked: VP.error,
    data: VP.configs,
    vpEnabled: remoteConfig.switches.vpEnabled,
    config: remoteConfig.configs.VP,
  };
};

const mapDispatchToProps = ({
  changeData,
  setError,
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
